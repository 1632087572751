export const buttons = {
  normal: {
    mobile: {
      fontSize: '1rem',
      padding: '0.6rem 1rem',
    },
    desktop: {
      fontSize: '2.1rem',
      padding: '1rem 2rem',
    },
  },
  large: {
    mobile: {
      fontSize: '2.1rem',
      padding: '1rem 3.0rem',
    },
    desktop: {
      fontSize: '4rem',
      padding: '1.5rem 3.0rem',
    },
  },
};
