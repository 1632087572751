import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

export const HamburgerButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 21px;
  outline: none;
  padding: 0;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 30px;

  span {
    background: ${({ color, theme }) => theme.colors[color]};
    border-radius: 9px;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
    width: 100%;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 10px;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 19px;
      transform-origin: left center;
    }
  }

  ${({ active }) =>
    active &&
    css`
      span {
        &:nth-child(1) {
          transform: rotate(40deg);
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-40deg);
        }
      }
    `}
`;

export const Hamburger = ({ active, color, onClick, ...props }) => {
  return (
    <HamburgerButton aria-label="Hamburger button" active={active} onClick={onClick} color={color} {...props}>
      <span />
      <span />
      <span />
    </HamburgerButton>
  );
};

Hamburger.propTypes = {
  active: PropTypes.bool.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Hamburger.defaultProps = {
  color: 'white',
  onClick: () => {},
};
