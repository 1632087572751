import React from 'react';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import globalStyle from 'GlobalStyle';
import Router from './Router';
import { theme } from './theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyle} />
      <Router />
    </ThemeProvider>
  );
};

export default App;
