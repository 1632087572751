import React from 'react';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/core';
import PropTypes from 'prop-types';

const scaelout = keyframes`
0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
`;

const Spinner = styled.div`
  -webkit-animation: ${scaelout} 1s infinite ease-in-out;
  animation: ${scaelout} 1s infinite ease-in-out;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 100%;
  height: 40px;
  margin: 100px auto;
  width: 40px;
`;

const Wrapper = styled.div`
  align-items: center;
  background: #000;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: ${({ fit }) => (fit ? 'absolute' : 'fixed')};
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Loader = ({ fit }) => {
  return (
    <Wrapper fit={fit}>
      <Spinner />
    </Wrapper>
  );
};

Loader.propTypes = {
  fit: PropTypes.bool,
};

Loader.defaultProps = {
  fit: false,
};
