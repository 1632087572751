import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';
import PropTypes from 'prop-types';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const Button = styled.button`
  &,&:visited {
    color: ${({ theme, textColor }) => theme.colors[textColor]};
  }

  align-items: center;
  appearance: none;
  background-color: ${({ theme, color }) => theme.colors[color]};
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 0;
  position: relative;
  text-decoration: none;
  transition: all 0.2s;
  user-select: none;
  white-space: nowrap;
  z-index:0;

  &:before{
    background: transparent;
    border: 1px solid ${({ theme, borderColor, color }) => theme.colors[borderColor || color]};
    content: "";
    height: 100%;
    left: 3px;
    position: absolute;
    top: 3px;
    transition: all 0.2s;
    width: 100%;
    z-index: -99 !important;

    ${({ theme }) => theme.mq.md`
    border-width: 3px;
    left: 7px;
    top: 7px;
    `}
  }

  &:hover{
    transform: translate(5%, 10%);
    &:before{
      left:0;
      top: 0;
    }
  }

  &:active{
    opacity: 0.7;
  }

  ${({ size, theme, fontSize }) =>
    size &&
    css`
      font-size: ${(fontSize && theme.font.size[fontSize]) || theme.buttons[size].mobile.fontSize};
      padding: ${theme.buttons[size].mobile.padding};
    `}


    ${({ theme, size, fontSize }) =>
      size &&
      theme.mq.md`
    font-size: ${(fontSize && theme.font.size[fontSize]) || theme.buttons[size].desktop.fontSize};
    padding: ${theme.buttons[size].desktop.padding};
    `}


  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}

  ${({ loading }) =>
    loading &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;

      * {
        display: none;
      }

      &::after {
        content: '';
        animation: ${rotate} 500ms infinite linear;
        border: 3px solid #000;
        border-radius: 50%;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        display: block;
        height: 1.2em;
        width: 1.2em;
        position: absolute;
        left: calc(50% - (1.2em / 2));
        top: calc(50% - (1.2em / 2));
        -webkit-transform-origin: center;
        transform-origin: center;
        position: absolute !important;
      }
    `}


`;

Button.propTypes = {
  borderColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large']).isRequired,
  textColor: PropTypes.string,
};

Button.defaultProps = {
  borderColor: '',
  color: 'primary',
  size: 'normal',
  textColor: 'black',
};
