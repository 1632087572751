import { css } from '@emotion/core';

import BebasNeueRegular from 'assets/fonts/BebasNeue/BebasNeue-Pro-Regular.otf';
import BebasNeueBold from 'assets/fonts/BebasNeue/BebasNeue-Pro-Bold.otf';
import BebasNeueLight from 'assets/fonts/BebasNeue/BebasNeue-Pro-Light.otf';

const globalStyle = css`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
  @font-face {
    font-display: swap;
    font-family: 'BebasNeue';
    font-weight: 300;
    src: url(${BebasNeueLight}) format('truetype');
  }

  @font-face {
    font-display: swap;
    font-family: 'BebasNeue';
    src: url(${BebasNeueRegular}) format('truetype');
  }

  @font-face {
    font-display: swap;
    font-family: 'BebasNeue';
    font-weight: 700;
    src: url(${BebasNeueBold}) format('truetype');
  }

  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  span,
  em,
  small,
  strong,
  sub,
  sup,
  mark,
  del,
  ins,
  strike,
  abbr,
  dfn,
  blockquote,
  q,
  cite,
  code,
  pre,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  div,
  section,
  article,
  main,
  aside,
  nav,
  header,
  hgroup,
  footer,
  img,
  figure,
  figcaption,
  address,
  time,
  audio,
  video,
  canvas,
  iframe,
  details,
  summary,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
  }

  /* Typography
 * *********************************** */

  html {
    font-size: 62.5%;
  }

  body {
    background-color: #000;
    font-family: 'BebasNeue', 'Helvetica Neue', sans-serif;
    font-size: 1.6rem;
    line-height: 1.2;
  }

  * {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a,
  a:visited {
    color: inherit;
  }

  /* Layout
 * *********************************** */

  article,
  aside,
  footer,
  header,
  nav,
  section,
  main {
    display: block;
  }

  * {
    box-sizing: border-box;
  }

  *:before,
  *:after {
    box-sizing: inherit;
  }

  /* Elements
 * *********************************** */

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  ol,
  ul {
    list-style: none;
  }

  img,
  video {
    max-width: 100%;
  }

  img {
    border-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:after,
  blockquote:before,
  q:after,
  q:before {
    content: '';
    content: none;
  }

  /* Attributes & states
 * *********************************** */

  [hidden] {
    display: none !important;
  }

  [disabled] {
    cursor: not-allowed;
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  /* Utility classes
 * *********************************** */

  .sr-only {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    left: -9999px;
    top: -9999px;
  }

  .double-size {
    height: 80rem !important;
    width: 80rem !important;
    .details {
      .info {
        font-size: 9.2rem !important;
      }
      .name {
        font-size: 18rem !important;
      }
    }
    .copyright {
      font-size: 2.4rem !important;
    }
  }

  .blur {
    filter: blur(5px);
  }
`;

export default globalStyle;
