import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { Loader } from 'components/atoms';

const Home = lazy(() => import('pages/Home'));
const About = lazy(() => import('pages/About'));
const Rep = lazy(() => import('pages/Rep'));

const Scroll = (props) => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      gaOptions: {
        ua: navigator.userAgent,
      },
    });
    ReactGA.pageview('/');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(props.location.pathname);
    return () => {};
  }, [props.location]);
  return props.children;
};

Scroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  location: PropTypes.objectOf(PropTypes.any),
};

const ScrollToTop = withRouter(Scroll);

const RouterComponent = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/rep" component={Rep} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default RouterComponent;
