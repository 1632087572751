import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';

const move = keyframes`
0% {
  background-position: 0 0;
}
100% {
  background-position: 50px 50px;
}
`;

export const Progressbar = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 500px;
  height: 0.5rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    background: ${({ theme }) => theme.colors.white};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: ${({ progress }) => progress}%;
    transition: all 0.1s;

    ${({
      animate,
      theme: {
        colors: { white },
      },
    }) =>
      animate &&
      css`
        background: linear-gradient(
          -45deg,
          ${white} 25%,
          transparent 25%,
          transparent 50%,
          ${white} 50%,
          ${white} 75%,
          transparent 75%,
          transparent
        );
        background-size: 50px 50px;
        animation: ${move} 2s linear infinite;
      `}
  }
`;
